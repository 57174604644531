import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import ExecutiveSummaryDashboard from "./ExecutiveSummaryDashboard";
import { CalendarSelector } from "../UI/UIComponents";
import { useLocation } from "react-router-dom";

function ExecutiveSummaryContent() {
  const [startDate, setStartDate] = useState(dayjs().subtract(12, "month"));
  const [endDate, setEndDate] = useState(dayjs());
  const [filterParam, setFilterParam] = useState();
  const [queryFiredOnLoad, setQueryFiredOnLoad] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: "/",
      page_title: "Executive Summary",
    });
  }, [location]);

  useEffect(() => {
    if (!queryFiredOnLoad) {
      setQueryFiredOnLoad(true);

      setFilterParam({
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [endDate, queryFiredOnLoad, startDate]);

  return (
    <div className="mainContent">
      <div className=" pl-32 pr-32">
        <div className="TitleSearch mt-26 mb-26 d-flex align-items-center justify-content-between">
          <h2 className="mainContentTitle">Executive Summary</h2>
        </div>
        <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
          <div className=" customerBillingForm spark-search-tab-card">
            <form action="" method="post">
              <div className="row">
                <CalendarSelector
                  dateValue={startDate}
                  setDateValue={setStartDate}
                  disabled={true}
                  disableFuture={true}
                  views={["year", "month"]}
                  label="Start Date"
                />

                <CalendarSelector
                  dateValue={endDate}
                  setDateValue={setEndDate}
                  disabled={true}
                  minDate={startDate}
                  disableFuture={true}
                  views={["year", "month"]}
                  label="End Date"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ExecutiveSummaryDashboard filterParam={filterParam} />
    </div>
  );
}

export default ExecutiveSummaryContent;
