import dayjs from "dayjs";
import React, { useState, useEffect, useMemo } from "react";
import { useLoaderData } from "react-router-dom";
import { useAuthContext } from "../../../store/AuthContext";
import {
  MultiSelector,
  CalendarSelector,
  SubmitButton,
} from "../../UI/UIComponents";
import RateAnalysisDashboard from "./RateAnalysisDashboard";
import { useLocation } from "react-router-dom";

function RateAnalysisContent() {
  const location = useLocation();
  const { clientConfig } = useAuthContext();

  const [rateCodeList, setRateCodeList] = useState([]);
  const [filterParam, setFilterParam] = useState();
  const [loading, setLoading] = useState();
  const [rateClass, setRateClass] = useState([]);
  const [rateCode, setRateCode] = useState([]);
  const [gridLocation, setGridLocation] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(12, "month"));
  const [endDate, setEndDate] = useState(dayjs());
  const [queryFiredOnLoad, setQueryFiredOnLoad] = useState(false);

  let minDate;
  if (clientConfig?.filters?.yearSpan) {
    minDate = dayjs().subtract(clientConfig.filters.yearSpan, "year");
  }

  const { rateClassesData, gridLocationFilterData } = useLoaderData();

  const rateClassList = useMemo(
    () =>
      rateClassesData.data.result.Items.map((item) => {
        return {
          ...item,
          value: item.rate_class,
        };
      }).filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.value === obj.value)
      ),
    [rateClassesData.data.result.Items]
  );

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: "/powerPNL/rateAnalysis",
      page_title: "Rate Analysis",
    });
  }, [location]);

  useEffect(() => {
    const codeList = rateClassesData.data.result.Items.filter((item) =>
      rateClass.includes(item.rate_class)
    ).map((item) => {
      return {
        ...item,
        controlValue: item.rate_class,
        value: item.rate_code,
      };
    });
    setRateCodeList(codeList);
  }, [rateClass, rateClassesData]);

  const gridLocationList = useMemo(
    () =>
      gridLocationFilterData.data.result.Items.map((item) => {
        return {
          ...item,
          value: item.billing_location,
        };
      }),
    [gridLocationFilterData]
  );

  useEffect(() => {
    if (!queryFiredOnLoad) {
      setQueryFiredOnLoad(true);
      setLoading(true);

      // Page is loaded with all grid locations and rate classes selected.
      const selectedClassList = rateClassList.map((item) => {
        return item.value;
      });
      const selectedCodeList = rateClassesData.data.result.Items.filter(
        (item) => selectedClassList.includes(item.rate_class)
      ).map((item) => item.rate_code);
      rateCodeList.map((item) => {
        return item.value;
      });

      setRateClass(selectedClassList);
      setRateCode(selectedCodeList);

      setFilterParam({
        rateClass: selectedClassList,
        rateCode: selectedCodeList,
        startDate: startDate,
        endDate: endDate,
        serviceArea: gridLocation,
      });
    }
  }, [
    queryFiredOnLoad,
    rateClass,
    rateCode,
    startDate,
    endDate,
    gridLocation,
    rateClassList,
    rateClassesData.data.result.Items,
    rateCodeList,
  ]);

  const filterHandler = () => {
    setLoading(true);
    setFilterParam({
      rateClass: rateClass,
      rateCode: rateCode,
      startDate: startDate,
      endDate: endDate,
      serviceArea: gridLocation,
    });
  };

  return (
    <div className="mainContent">
      <div className=" pl-32 pr-32">
        <div className="TitleSearch mt-26 mb-26 d-flex align-items-center justify-content-between">
          <h2 className="mainContentTitle">Rate Analysis</h2>
        </div>

        <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
          <div className=" customerBillingForm spark-search-tab-card">
            <form action="" method="post">
              <div className="row">
                <MultiSelector
                  disabled={rateCode.length > 0}
                  value={rateClass}
                  setValue={setRateClass}
                  valueList={rateClassList}
                  label="Rate Class"
                />

                <MultiSelector
                  disabled={rateCodeList.length === 0}
                  value={rateCode}
                  setValue={setRateCode}
                  valueList={rateCodeList}
                  label="Rate Code"
                />

                <MultiSelector
                  value={gridLocation}
                  setValue={setGridLocation}
                  valueList={gridLocationList}
                  label="Grid Location"
                />

                <CalendarSelector
                  dateValue={startDate}
                  setDateValue={setStartDate}
                  minDate={minDate}
                  disableFuture={true}
                  views={["year", "month"]}
                  label="Start Date"
                />

                <CalendarSelector
                  dateValue={endDate}
                  setDateValue={setEndDate}
                  minDate={startDate}
                  disableFuture={true}
                  views={["year", "month"]}
                  label="End Date"
                />

                <SubmitButton filterHandler={filterHandler} loading={loading} />
              </div>
            </form>
          </div>
        </div>
      </div>
      <RateAnalysisDashboard
        filterParam={filterParam}
        setLoading={setLoading}
      />
    </div>
  );
}

export default RateAnalysisContent;
