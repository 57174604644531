import dayjs from "dayjs";
import React, { useMemo, useState, useEffect } from "react";
import WhatIfScenarioDashboard from "./WhatIfScenarioDashboard";
import { useLoaderData } from "react-router-dom";
import { useAuthContext } from "../../../store/AuthContext";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";

import {
  CalendarSelector,
  MultiSelector,
  SubmitButton,
} from "../../UI/UIComponents";

function WhatIfScenarioContent() {
  const location = useLocation();
  const { clientConfig } = useAuthContext();

  const [supplier, setSupplier] = useState([]);
  const [rateCode, setRateCode] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(12, "month"));
  const [endDate, setEndDate] = useState(dayjs());
  const [filterParam, setFilterParam] = useState();
  const [loading, setLoading] = useState();

  const [rateCodeExpanded, setRateCodeExpanded] = useState("");
  const [supplierExpanded, setSupplierExpanded] = useState("");
  const [queryFiredOnLoad, setQueryFiredOnLoad] = useState(false);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: "/powerPNL/whatIfScenario",
      page_title: "What-if Scenario",
    });
  }, [location]);

  let minDate;
  if (clientConfig?.filters?.yearSpan) {
    minDate = dayjs().subtract(clientConfig.filters.yearSpan, "year");
  }

  const {
    supplierFilterData,
    rateClassesData,
    rateCalcDefaultData,
    supplierCalcDefaultData,
  } = useLoaderData();
  const supplierList = useMemo(
    () =>
      supplierFilterData.data.result.Items.map((item) => {
        return {
          ...item,
          value: item.supplier_name,
        };
      }),
    [supplierFilterData.data.result.Items]
  );

  const rateCodeList = rateClassesData.data.result.Items.map((item) => {
    return {
      ...item,
      controlValue: item.rate_class,
      value: item.rate_code,
    };
  }).sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));

  const baseRateLabels = {
    serviceFeePerDay: { top: "Service Availability Fee", bottom: "$/day" },
    facilityCharge: { top: "Facility Charge", bottom: "$/month" },
    serviceAvailability: { top: "Service Availability Fee", bottom: "$/month" },
    capacityChargeKwh: { top: "Capacity Charge kWh", bottom: "$/year" },
    demandChargeKw: { top: "Demand Charge kW", bottom: "$/kW" },
    minimumChargeKw: { top: "Minimum Charge kW", bottom: "$/kW" },
    coincidentDemandCharge: {
      top: "Coincidental Demand Charge kW",
      bottom: "$/kW",
    },
    offpeakDemandCharge: { top: "Off-Peak Demand Charge", bottom: "$/kW" },
    existingXfrmr: { top: "Existing XFRMR", bottom: "$/month" },
    dedicatedXfrmr: { top: "Dedicated XFRMR", bottom: "$/month" },
    "100kwhBlock": { top: "100 kWh Block", bottom: "$/block" },
    thresholds: { top: "", bottom: "$/kWh" },
  };

  const [defaultRateParam, thresholdLabels] = useMemo(() => {
    const rateParams = _.chain(rateCalcDefaultData.data.result.Items)
      .groupBy((item) => item.rate)
      .mapValues((group) => {
        return {
          // threshold_starts: _.map(group, 'start_kwh_threshold'),
          // threshold_ends: _.map(group, 'end_kwh_threshold'),
          // kwhCosts: _.map(group, 'kwh_cost'),

          ..._.map(group, "start_kwh_threshold").reduce(
            (accumulator, value, index) => {
              return { ...accumulator, [`threshold${index + 1}Start`]: value };
            },
            {}
          ),
          ..._.map(group, "end_kwh_threshold").reduce(
            (accumulator, value, index) => {
              return { ...accumulator, [`threshold${index + 1}End`]: value };
            },
            {}
          ),
          ..._.map(group, "kwh_cost").reduce((accumulator, value, index) => {
            return { ...accumulator, [`threshold${index + 1}Cost`]: value };
          }, {}),
          serviceFeePerDay:
            group[0].service_fee_per_day !== undefined
              ? `${group[0].service_fee_per_day}`
              : group[0].service_fee_per_day,
          facilityCharge:
            group[0].facility_charge !== undefined
              ? `${group[0].facility_charge}`
              : group[0].facility_charge,
          serviceAvailability:
            group[0].service_availability !== undefined
              ? `${group[0].service_availability}`
              : group[0].service_availability,
          capacityChargeKwh:
            group[0].capacity_charge_kwh !== undefined
              ? `${group[0].capacity_charge_kwh}`
              : group[0].capacity_charge_kwh,
          demandChargeKw:
            group[0].demand_charge_kw !== undefined
              ? `${group[0].demand_charge_kw}`
              : group[0].demand_charge_kw,
          minimumChargeKw:
            group[0].minimum_charge_kw !== undefined
              ? `${group[0].minimum_charge_kw}`
              : group[0].minimum_charge_kw,
          coincidentDemandCharge:
            group[0].coincident_demand_charge !== undefined
              ? `${group[0].coincident_demand_charge}`
              : group[0].coincident_demand_charge,
          offpeakDemandCharge:
            group[0].offpeak_demand_charge !== undefined
              ? `${group[0].offpeak_demand_charge}`
              : group[0].offpeak_demand_charge,
          existingXfrmr:
            group[0].existing_xfrmr !== undefined
              ? `${group[0].existing_xfrmr}`
              : group[0].existing_xfrmr,
          dedicatedXfrmr:
            group[0].dedicated_xfrmr !== undefined
              ? `${group[0].dedicated_xfrmr}`
              : group[0].dedicated_xfrmr,
          "100kwhBlock":
            group[0]["100_kwh_block"] !== undefined
              ? `${group[0]["100_kwh_block"]}`
              : group[0]["100_kwh_block"],
        };
      })
      .value();

    const thresholdLabels = _.chain(rateCalcDefaultData.data.result.Items)
      .groupBy((item) => item.rate)
      .mapValues((group) => {
        let labels = {};
        const startValues = _.map(group, "start_kwh_threshold");
        const endValues = _.map(group, "end_kwh_threshold");

        if (endValues.length === 1 && startValues.length === 1) {
          labels = { [`threshold1Cost`]: "Flat Rate kWh" };
        } else {
          endValues.forEach((_, index) => {
            if (startValues[index] === 0) {
              labels = {
                ...labels,
                [`threshold${index + 1}Cost`]: `First ${endValues[index]} kWh`,
              };
            } else if (endValues.length - 1 === index) {
              labels = {
                ...labels,
                [`threshold${
                  index + 1
                }Cost`]: `Flat Rate > ${startValues[index]} kWh`,
              };
            } else {
              labels = {
                ...labels,
                [`threshold${
                  index + 1
                }Cost`]: `Rate ${startValues[index]} kWh - ${endValues[index]} kWh`,
              };
            }
          });
        }
        return labels;
      })
      .value();
    return [rateParams, thresholdLabels];
  }, [rateCalcDefaultData.data.result.Items]);

  // console.log(defaultRateParam);

  const { register, formState, handleSubmit, reset, getValues } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultRateParam,
  });

  const baseSupplierLabels = {
    energyCharge: { top: "Energy Charges", bottom: "$/kWh" },
    demandBaseComp: { top: "Demand Base Component", bottom: "$/kW" },
    demandBaseCompDisc: { top: "Demand Base C. Discount", bottom: "$/kW" },
    demandPeakComp: { top: "Demand Peaking Component", bottom: "$/kW" },
    demandPeakCompDisc: { top: "Demand Peaking C. Discount", bottom: "$/kW" },
  };

  const [defaultSupplierParam, supplierLabels] = useMemo(() => {
    const rateParams = _.chain(supplierCalcDefaultData.data.result.Items)
      .groupBy((item) => item.supplier)
      .mapValues((group) => {
        return {
          ..._.map(group, "d_price_dollars").reduce(
            (accumulator, value, index) => {
              return {
                ...accumulator,
                [group[index].s_description
                  .replace(/\s/g, "")
                  .split("(")[0]
                  .trim()]: value,
              };
            },
            {}
          ),
          energyCharge:
            group[0].d_energy_charge_dollars_per_kwh !== undefined
              ? `${group[0].d_energy_charge_dollars_per_kwh}`
              : group[0].d_energy_charge_dollars_per_kwh,
          demandBaseComp:
            group[0].d_demand_base_component_dollars_per_kw !== undefined
              ? `${group[0].d_demand_base_component_dollars_per_kw}`
              : group[0].d_demand_base_component_dollars_per_kw,
          demandBaseCompDisc:
            group[0].d_demand_base_component_discount_dollars_per_kw !==
            undefined
              ? `${group[0].d_demand_base_component_discount_dollars_per_kw}`
              : group[0].d_demand_base_component_discount_dollars_per_kw,
          demandPeakComp:
            group[0].d_demand_peak_component_dollars_per_kw !== undefined
              ? `${group[0].d_demand_peak_component_dollars_per_kw}`
              : group[0].d_demand_peak_component_dollars_per_kw,
          demandPeakCompDisc:
            group[0].d_demand_peak_component_discount_dollars_per_kw !==
            undefined
              ? `${group[0].d_demand_peak_component_discount_dollars_per_kw}`
              : group[0].d_demand_peak_component_discount_dollars_per_kw,
        };
      })
      .value();

    const supplierLabels = _.chain(supplierCalcDefaultData.data.result.Items)
      .groupBy((item) => item.supplier)
      .mapValues((group) => {
        let labels = {};
        const costs = _.map(group, "d_price_dollars");
        const descriptions = _.map(group, "s_description");

        costs.forEach((_, index) => {
          labels = {
            ...labels,
            [descriptions[index].replace(/\s/g, "").split("(")[0].trim()]: {
              top: descriptions[index].split("(")[0].trim(),
              bottom: "$",
            },
          };
        });
        return labels;
      })
      .value();
    return [rateParams, supplierLabels];
  }, [supplierCalcDefaultData.data.result.Items]);

  const {
    register: registerSupplier,
    formState: formStateSupplier,
    handleSubmit: handleSubmitSupplier,
    reset: resetSupplier,
    getValues: getSupplierValues,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultSupplierParam,
  });

  const resetRateCalcHandler = () => {
    reset();
  };

  const handleRateAccordion = (rateCodeValue) => (_, newExpanded) => {
    setRateCodeExpanded(newExpanded ? rateCodeValue : "");
  };

  const handleSupplierAccordion = (supplierValue) => (_, newExpanded) => {
    setSupplierExpanded(newExpanded ? supplierValue : "");
  };

  const resetSupplierCalcHandler = () => {
    resetSupplier();
  };

  useEffect(() => {
    if (!queryFiredOnLoad) {
      setQueryFiredOnLoad(true);
      setLoading(true);

      setFilterParam({
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [endDate, queryFiredOnLoad, startDate]);

  const getRateParamValues = () => {
    const rateFormValues = getValues();
    let index = 0,
      currentParamValues = [];
    const filteredResults =
      Object.keys(
        rateFormValues
      ); /* .filter((rateCode) => formState.dirtyFields[rateCode] !== undefined) */ // Commented code will restrict to modified rates only
    return filteredResults.reduce((rateAccumulator, rateCode, rateIndex) => {
      let nextParamValue = Object.keys(rateFormValues[rateCode]).reduce(
        (accumulator, rateParam) => {
          // if(rateFormValues[rateCode][rateParam] !== undefined && formState.dirtyFields[rateCode][rateParam] === true) {
          accumulator = {
            ...accumulator,
            [rateParam]: rateFormValues[rateCode][rateParam],
          }; // Commented code will restrict to modified tariffs only
          // }
          return accumulator;
        },
        { rateCode: rateCode }
      );
      if (
        JSON.stringify(currentParamValues).length +
          JSON.stringify(nextParamValue).length >
        800
      ) {
        // AWS has a string limit.  This value is below that limit.
        rateAccumulator[`rateCalcParam${index}`] = JSON.stringify({
          Values: currentParamValues,
        });
        currentParamValues = [];
        ++index;
      }
      currentParamValues.push(nextParamValue);
      if (rateIndex === filteredResults.length - 1) {
        rateAccumulator[`rateCalcParam${index}`] = JSON.stringify({
          Values: currentParamValues,
        });
      }
      return rateAccumulator;
    }, {});
  };

  const getSupplierParamValues = () => {
    const supplierFormValues = getSupplierValues();
    let index = 0,
      currentParamValues = [];
    const filteredResults =
      Object.keys(
        supplierFormValues
      ); /* .filter((supplier) => formStateSupplier.dirtyFields[supplier] !== undefined) */
    return filteredResults.reduce(
      (supplierAccumulator, supplier, supplierIndex) => {
        let nextParamValue = Object.keys(supplierFormValues[supplier]).reduce(
          (accumulator, supplierParam) => {
            // if(supplierFormValues[supplier][supplierParam] !== undefined && formStateSupplier.dirtyFields[supplier][supplierParam] === true) {
            accumulator = {
              ...accumulator,
              [supplierParam]: supplierFormValues[supplier][supplierParam],
            };
            // }
            return accumulator;
          },
          { supplier: supplier }
        );
        if (
          JSON.stringify(currentParamValues).length +
            JSON.stringify(nextParamValue).length >
          800
        ) {
          supplierAccumulator[`supplierCalcParam${index}`] = JSON.stringify({
            Values: currentParamValues,
          });
          currentParamValues = [];
          ++index;
        }
        currentParamValues.push(nextParamValue);
        if (supplierIndex === filteredResults.length - 1) {
          supplierAccumulator[`supplierCalcParam${index}`] = JSON.stringify({
            Values: currentParamValues,
          });
        }
        return supplierAccumulator;
      },
      {}
    );
  };

  const filterHandler = () => {
    setLoading(true);

    let filterParam = {
      startDate: startDate,
      endDate: endDate,
    };

    filterParam =
      formState.isDirty && formState.isValid
        ? { ...filterParam, ...getRateParamValues() }
        : filterParam;
    filterParam =
      formStateSupplier.isDirty && formStateSupplier.isValid
        ? { ...filterParam, ...getSupplierParamValues() }
        : filterParam;

    setFilterParam(filterParam);
  };

  return (
    <div className="mainContent">
      <div className="pl-32 pr-32">
        <div className="TitleSearch mt-26 mb-26 d-flex align-items-center justify-content-between">
          <h2 className="mainContentTitle">What-if Scenario</h2>
        </div>

        <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
          <div className=" customerBillingForm spark-search-tab-card">
            <form action="" method="post">
              <div className="row">
                <CalendarSelector
                  dateValue={startDate}
                  setDateValue={setStartDate}
                  minDate={minDate}
                  disableFuture={true}
                  views={["year", "month"]}
                  label="Start Date"
                />

                <CalendarSelector
                  dateValue={endDate}
                  setDateValue={setEndDate}
                  minDate={startDate}
                  disableFuture={true}
                  views={["year", "month"]}
                  label="End Date"
                />

                <div className="col-6" />

                <SubmitButton filterHandler={filterHandler} loading={loading} />
              </div>
            </form>
          </div>
        </div>
      </div>
      <WhatIfScenarioDashboard
        filterParam={filterParam}
        setLoading={setLoading}
      />

      <div className="pl-32 pr-32">
        <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
          <div className="customerBillingForm spark-search-tab-card">
            <form onSubmit={handleSubmit(filterHandler)}>
              <div className="row rateCalc">
                <h3 className="mainContentTitle">Rate Adjustment</h3>
                <MultiSelector
                  value={rateCode}
                  setValue={setRateCode}
                  valueList={rateCodeList}
                  label="Rate Code"
                />
                <div className="col-lg-5 col-md-6 mb-lg-0 mb-1" />
                <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 align-flex-end">
                  <Button
                    variant="small"
                    type="submit"
                    disabled={
                      !formState.isDirty || !formState.isValid || loading
                    }
                  >
                    Recalculate
                  </Button>
                </div>
                <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 align-flex-end">
                  <Button
                    variant="small"
                    disabled={!formState.isDirty || loading}
                    onClick={resetRateCalcHandler}
                  >
                    Reset Values
                  </Button>
                </div>
              </div>

              {rateCode.length > 0 && (
                <div className="row rateCalc">
                  <hr />
                </div>
              )}

              {rateCode.sort().map((rateCodeValue, index) => {
                return (
                  <div key={`rateRow${index}`} className="row rateCalc">
                    <Accordion
                      expanded={rateCodeExpanded === rateCodeValue}
                      onChange={handleRateAccordion(rateCodeValue)}
                    >
                      <AccordionSummary>
                        <Typography
                          className={`${
                            formState.errors[rateCodeValue] !== undefined
                              ? "text-danger"
                              : formState.dirtyFields[rateCodeValue] !==
                                  undefined ||
                                Object.values(
                                  defaultRateParam[rateCodeValue]
                                ).filter((value) => value !== undefined)
                                  .length === 0
                              ? "text-info"
                              : ""
                          }`}
                        >
                          Rate {rateCodeValue}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="row rateCalc">
                        {Object.keys(baseRateLabels).map((index) => {
                          if (
                            defaultRateParam[rateCodeValue][index] !== undefined
                          ) {
                            return (
                              <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 pad-0">
                                <label
                                  style={{ maxWidth: "95%" }}
                                  htmlFor={index}
                                  className={`form-label${
                                    formState.errors[rateCodeValue]?.[index] !==
                                    undefined
                                      ? " text-danger"
                                      : ""
                                  }`}
                                >
                                  {baseRateLabels[index].top}
                                </label>
                                <br />
                                <input
                                  style={{ maxWidth: "95%" }}
                                  type="number"
                                  step="any"
                                  name={index}
                                  className="pl-0 pr-0"
                                  {...register(`${rateCodeValue}.${index}`, {
                                    required: true,
                                    pattern: {
                                      value: /^\d+(\.\d)?\d*$/,
                                    },
                                    validate: (value) =>
                                      value !== undefined && value !== "",
                                  })}
                                />
                                <br />({baseRateLabels[index].bottom})
                              </div>
                            );
                          }
                          return <></>;
                        })}

                        {Object.keys(thresholdLabels[rateCodeValue]).map(
                          (index) => {
                            if (
                              defaultRateParam[rateCodeValue][index] !==
                              undefined
                            ) {
                              return (
                                <div className="col-lg-2 col-md-6 mb-lg-0 mb-1">
                                  <label
                                    style={{ maxWidth: "95%" }}
                                    htmlFor={index}
                                    className={`form-label${
                                      formState.errors[rateCodeValue]?.[
                                        index
                                      ] !== undefined
                                        ? " text-danger"
                                        : ""
                                    }`}
                                  >
                                    {thresholdLabels[rateCodeValue][index]}
                                  </label>
                                  <br />
                                  <input
                                    style={{ maxWidth: "95%" }}
                                    type="number"
                                    step="any"
                                    name={index}
                                    className="pl-0 pr-0"
                                    {...register(`${rateCodeValue}.${index}`, {
                                      required: true,
                                      pattern: {
                                        value: /^\d+(\.\d)?\d*$/,
                                      },
                                      validate: (value) =>
                                        value !== undefined && value !== "",
                                    })}
                                  />
                                  <br />({baseRateLabels.thresholds.bottom})
                                </div>
                              );
                            }
                            return <></>;
                          }
                        )}

                        {Object.values(defaultRateParam[rateCodeValue]).filter(
                          (value) => value !== undefined
                        ).length === 0 && (
                          <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 pad-0">
                            <h3 className="text-info">No Tariffs Found</h3>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </form>
          </div>
        </div>

        <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime mb-180">
          <div className="customerBillingForm spark-search-tab-card">
            <form onSubmit={handleSubmitSupplier(filterHandler)}>
              <div className="row rateCalc">
                <h3 className="mainContentTitle">Supplier Adjustment</h3>
                <MultiSelector
                  value={supplier}
                  setValue={setSupplier}
                  valueList={supplierList}
                  label="Supplier"
                />

                <div className="col-lg-5 col-md-6 mb-lg-0 mb-1" />
                <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 align-flex-end">
                  <Button
                    variant="small"
                    type="submit"
                    disabled={
                      !formStateSupplier.isDirty ||
                      !formStateSupplier.isValid ||
                      loading
                    }
                  >
                    Recalculate
                  </Button>
                </div>
                <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 align-flex-end">
                  <Button
                    variant="small"
                    disabled={!formStateSupplier.isDirty || loading}
                    onClick={resetSupplierCalcHandler}
                  >
                    Reset Values
                  </Button>
                </div>
              </div>
              {supplier.length > 0 && (
                <div className="row rateCalc">
                  <hr />
                </div>
              )}
              {supplier.sort().map((supplierValue, index) => {
                return (
                  <div key={`supplierRow${index}`} className="row rateCalc">
                    <Accordion
                      expanded={supplierExpanded === supplierValue}
                      onChange={handleSupplierAccordion(supplierValue)}
                    >
                      <AccordionSummary>
                        <Typography
                          className={`${
                            formStateSupplier.dirtyFields[supplierValue] !==
                            undefined
                              ? "text-info"
                              : ""
                          }`}
                        >
                          Supplier {supplierValue}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="row rateCalc">
                        {Object.values(
                          defaultSupplierParam[supplierValue]
                        ).filter((value) => value !== undefined).length ===
                        0 ? (
                          <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 pad-0">
                            <h3 className="text-info">
                              No Supplier Data Found
                            </h3>
                          </div>
                        ) : (
                          <>
                            <div className="row rateCalc">
                              {Object.keys(baseSupplierLabels).map((index) => {
                                if (
                                  defaultSupplierParam[supplierValue][index] !==
                                  undefined
                                ) {
                                  return (
                                    <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 pad-0">
                                      <label
                                        style={{ maxWidth: "95%" }}
                                        htmlFor={index}
                                        className={`form-label${
                                          formStateSupplier.errors[
                                            supplierValue
                                          ]?.[index] !== undefined
                                            ? " text-danger"
                                            : ""
                                        }`}
                                      >
                                        {baseSupplierLabels[index].top}
                                      </label>
                                      <br />
                                      <input
                                        style={{ maxWidth: "95%" }}
                                        type="number"
                                        step="any"
                                        name={index}
                                        className="pl-0 pr-0"
                                        {...registerSupplier(
                                          `${supplierValue}.${index}`,
                                          {
                                            required: true,
                                            pattern: {
                                              value: /^\d+(\.\d)?\d*$/,
                                            },
                                            validate: (value) =>
                                              value !== undefined &&
                                              value !== "",
                                          }
                                        )}
                                      />
                                      <br />({baseSupplierLabels[index].bottom})
                                    </div>
                                  );
                                }
                                return <></>;
                              })}
                            </div>

                            <div className="row rateCalc">
                              {Object.keys(supplierLabels[supplierValue]).map(
                                (index) => {
                                  if (
                                    defaultSupplierParam[supplierValue][
                                      index
                                    ] !== undefined
                                  ) {
                                    return (
                                      <div className="col-lg-2 col-md-6 mb-lg-0 mb-1 pad-0">
                                        <label
                                          style={{ maxWidth: "95%" }}
                                          htmlFor={index}
                                          className={`form-label${
                                            formStateSupplier.errors[
                                              supplierValue
                                            ]?.[index] !== undefined
                                              ? " text-danger"
                                              : ""
                                          }`}
                                        >
                                          {
                                            supplierLabels[supplierValue][index]
                                              .top
                                          }
                                        </label>
                                        <br />
                                        <input
                                          style={{ maxWidth: "95%" }}
                                          type="number"
                                          step="any"
                                          name={index}
                                          className="pl-0 pr-0"
                                          {...registerSupplier(
                                            `${supplierValue}.${index}`,
                                            {
                                              required: true,
                                              pattern: {
                                                value: /^\d+(\.\d)?\d*$/,
                                              },
                                              validate: (value) =>
                                                value !== undefined &&
                                                value !== "",
                                            }
                                          )}
                                        />
                                        <br />(
                                        {
                                          supplierLabels[supplierValue][index]
                                            .bottom
                                        }
                                        )
                                      </div>
                                    );
                                  }
                                  return <></>;
                                }
                              )}
                            </div>
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatIfScenarioContent;
